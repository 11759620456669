import _process from "process";
var exports = {};
var process = _process;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logWarning = void 0;
function logWarning(...args) {
  if (!process.env["NEAR_NO_LOGS"]) {
    console.warn(...args);
  }
}
exports.logWarning = logWarning;
export default exports;