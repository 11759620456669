import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTransactionLastResult = void 0;
/** @hidden */
function getTransactionLastResult(txResult) {
  if (typeof txResult.status === "object" && typeof txResult.status.SuccessValue === "string") {
    const value = Buffer.from(txResult.status.SuccessValue, "base64").toString();
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
  return null;
}
exports.getTransactionLastResult = getTransactionLastResult;
export default exports;